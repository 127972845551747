import React, { useEffect, useState, Fragment } from "react";
import Login from "./Login";
import queryString from 'query-string';
import Constants from "../../store/config/Constants";
import AuthPageSplash from '../../partials/components/Auth/AuthPageSplash';
import { injectIntl } from "react-intl";
import { Button } from "devextreme-react";
import LoginTopBar from "./LoginTopBar";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fondoImagen: {
    backgroundImage: `url(media/bg/2personnel_bg.jpg)`,
    backgroundSize: "cover",
  },
}));

function AuthPage(props) {
  const { intl } = props;

  const [setViewLogin,] = useState(false);
  const [btnIniciarOpenDrawer, setBtnIniciarOpenDrawer] = useState(false);
  const classes = useStyles();

  let params = queryString.parse(props.location.search)

  const loadScriptByURL = (id, url, callback) => {
    //Registrar reCaptcha invisible de Google.
    //Referencia:>> https://www.akashmittal.com/google-recaptcha-reactjs/
    const isScriptExist = document.getElementById(id);
    if (!isScriptExist) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }
    if (isScriptExist && callback) callback();
  }

  useEffect(() => {
    //-> 2 ESTA LINEA PINTA EL PRIMER INGRESO EN BACKGROUD EN INICIO
    document.body.classList.add(classes.fondoImagen)
    if (params != undefined) {
      if (params.confirm != undefined && params.confirm.length > 5) {
        setViewLogin(true);
      }
    }

    loadScriptByURL("recaptcha-key", `${Constants.CAPTCHA_URL}?render=${Constants.CAPTCHA_SECRETKEYSITE}`, function () {
    });

  }, []);

  const [onLandingPage, setOnLandingPage] = useState(true)
  const personnelLogo = "/media/imgv1/2personnel_w.png";

  function handleAcreditacionChange() {
    setOnLandingPage(prevState => !prevState)
  }
  const btnIniciar = () => {
    setBtnIniciarOpenDrawer(!btnIniciarOpenDrawer)
  };

  return (
    <Fragment>

      <header className="clsHeaderMain">
        <div>
          <img src={personnelLogo} style={{ position: 'relative', marginLeft: '45px', padding: '19px 89px 20px 0px', width: '33rem' }} />
        </div>
        <nav>
          <div className={onLandingPage ? "loginHomeGalleryImage" : "loginHomeImage"}>
            <AuthPageSplash handleAcreditacionChange={handleAcreditacionChange} showImageGallery={false} />
          </div>
        </nav>
      </header>

      <div className="clsHomeRow">
        <div className="clsloginColumn">
          <LoginTopBar handleAcreditacionChange={handleAcreditacionChange} style={{ display: "none" }} btnIniciarOpenDrawer={btnIniciarOpenDrawer} setBtnIniciarOpenDrawer={setBtnIniciarOpenDrawer} />

        </div>

        {
          onLandingPage ?
            <div id="loginLeftColumn" className="clsDescripcionWeb">
              {/* <h1 style={{ fontSize: "32px", marginBottom: '2.5rem' }}> {intl.formatMessage({ id: "DESCRIPTION.WEB.MAIN" })}</h1> */}
              <Button
                icon="media/imgv1/arrow_right_negro.svg"
                className="classEfectoYellow"
                text={intl.formatMessage({ id: "LOG.IN.INICIAR" })}
                type="default"
                rtlEnabled={true}
                style={{ width: "10.5rem", justifyContent: "center" }}
                onClick={btnIniciar}
              />
            </div>
            : <Login />
        }


      </div>
    </Fragment>
  );
}

export default injectIntl(AuthPage);
